<template>
  <div class="modal-footer">
    <button class="main"
      @click="closePreview()">
      1 | {{translate('general_back')}}
    </button>
    <button class="action"
      v-for="action in actions"
      :key="action.id"
      @click="action.id === 'ticketRebet'
      ? callRebet()
      : actionTicketPreview(action)">
      {{ action.key }} | {{ action.text }}
    </button>
  </div>
</template>

<script>
import filter from 'lodash/filter';
import { mapGetters, mapActions } from 'vuex';
import EventBus from '@/store/EventBus';

export default {
  name: 'TicketPreviewFooter',
  methods: {
    ...mapActions([
      'closeTicketPreview',
      'busServiceSendMessage',
      'reBet',
      'toggleShopSettings',
    ]),
    actionTicketPreview(action) {
      const data = {
        ticket: this.ticketPreviewData.ticket,
        action: {
          id: action.id,
        },
      };
      this.busServiceSendMessage({
        action: 'Tickets.Action',
        data,
      });
      this.closePreview();
    },
    closePreview() {
      this.toggleShopSettings(true);
      this.closeTicketPreview();
    },
    callRebet() {
      this.reBet({
        data: {
          ticket: this.ticketPreviewData.ticket,
        },
      });
      this.closePreview();
    },
  },
  computed: {
    ...mapGetters([
      'ticketPreviewData',
      'translate',
      'config',
    ]),
    actions() {
      return this.ticketPreviewData
        ? filter(this.ticketPreviewData.actionData.actions, (action) => {
          let isValid = true;
          if (action.id === 'ticketCancel') {
            const tempTime = new Date().getTime();
            isValid = (tempTime - this.ticketPreviewData.ticket.ticketDateTimeUTC)
              <= this.config.rules.maxTicketCancellationTime.value * 1000;
          }
          if (action.id === 'ticketRebet') {
            isValid = filter(this.ticketPreviewData.ticket.bets, (bet) => bet.ticketBetStatus.toLowerCase() !== 'open').length === 0;
          }
          return isValid;
        }) : [];
    },
  },
  mounted() {
    EventBus.$on('ticketPreviewKeydown', (event) => {
      const validAction = filter(this.actions, (action) => action.key === +event.key);
      const action = validAction[0]?.id || ((+event.key) === 1 ? 1 : null);
      if (action) {
        switch (action) {
          case 1:
            this.closePreview();
            break;
          case 'ticketRebet':
            this.callRebet();
            break;
          default:
            this.actionTicketPreview(validAction[0]);
            break;
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.modal-footer {
  align-items: flex-end;
  display: flex;
  height: 81px;

  button {
    border-radius: 3px;
    font-size: 16px;
    height: 40px;
    margin: 0 10px;
    width: 100%;

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
  .main {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    color: #455a64;
  }

  .action {
    background-color: #1f9bdd;
    border: 1px solid #1f9bdd;
    color: #ffffff;
  }
}
</style>
