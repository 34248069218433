<template>
  <table class="ticket-details"
         aria-describedby="ticketPreviewHeader">
    <thead>
    <tr>
      <th v-for="(item, index) in titles"
          scope="col"
          :key="index">{{ translate(item) }}</th>
    </tr>
    </thead>
    <tbody>
      <tr v-for="(bet, betIndex) in ticketPreviewBets"
        :key="`bet_${betIndex}`">
        <td>
          <em class="icon"
             :class="getStatusIcon(bet.ticketBetStatus.toLowerCase())"></em>
        </td>
        <td>{{ bet.lotteryDisplayId }}</td>
        <td>{{ formatMatchDate(bet.matchDateTimeUTC) }}</td>
        <td>{{ bet.matchName }}</td>
        <td>{{ bet.bet.betShortName }}</td>
        <td>
          <span v-if="!bet.bet.playedNumbers && bet.bet.bonusBalls === null">
          {{bet.betOutcome.betOutcomeShortName}}
          </span>
          <div class="numbers-bonus-wrapper"
               v-else>
            <div class="numbers"
                 v-if="bet.bet.playedNumbers">
              <span>
                {{bet.bet.playedNumbers}}
              </span>
            </div>
            <div class="bonus-balls"
                 v-if="bet.bet.bonusBalls">
                <span class="bonus-ball">
                {{bet.bet.bonusBalls}}
              </span>
            </div>
          </div>
          </td>
        <td>{{ bet.oddValue.toFixed(2) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatFns } from '@/utility';

export default {
  name: 'TicketPreviewDetails',
  data() {
    return {
      titles: [
        'general_status',
        'general_id',
        'general_time',
        'general_event',
        'general_bet',
        'general_pick',
        'general_odd',
      ],
    };
  },
  computed: {
    ...mapGetters(['ticketPreviewData', 'translate', 'ticketPreviewBets']),
  },
  methods: {
    formatMatchDate(date) {
      return formatFns('DD.MM.YYYY HH:mm:ss', date);
    },
    results(result) {
      if (result.length) {
        return result[0].match_result;
      }
      return '-';
    },
    // agregatePlayedNumbers(data) {
    //   const numbers = typeof data === 'string' ? data : data.toString();
    //   return numbers.split(',');
    // },
    getStatusIcon(ticketBetStatus) {
      let icon;
      let color;
      switch (ticketBetStatus) {
        case 'won':
        case 'payedout':
          icon = 'icon-check-a';
          color = 'positiv';
          break;
        case 'open':
          icon = 'icon-clock';
          color = 'neutral';
          break;
        case 'lost':
          icon = 'icon-void';
          color = 'negative';
          break;
        case 'cancel':
          icon = 'icon-close';
          color = 'negative';
          break;
        default:
          break;
      }
      return `${icon} ${color}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-details {
  border-collapse: collapse;
  border-radius: 2px;
  border: 1px solid #ececed;
  color: #455a64;
  width: 100%;
  thead {
    border-bottom: 1px solid #ececed;
    font-size: 14px;
    text-transform: capitalize;
  }
  th,
  td {
    padding: 8px;
    text-align: left;
    .numbers-bonus-wrapper {
      display: flex;
      //.numbers,
      //.bonus-balls {
      //  span:not(:last-child) {
      //    &:after {
      //      content: ',';
      //    }
      //  }
      //}
    }
  }
  .bonus-ball {
    color: #ffc107
  }
  .positive {
    color: #388e3c;
  }
  .negative {
    color: #e53935;
  }
  .neutral {
    color: inherit;
  }
}
</style>
